import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const LanguageNav = ({
    active,
    locale,
    uri,
    languageToggle,
    mobile,
    enabledLocales,
}) => (
    <>
        {enabledLocales.length > 1 ?
            <div className={classnames("language-wrap", { active })}>
                <div className="container-fluid">
                    <div className="row">
                    <div className="col">
                        <ul className="language-select-list">
                            {enabledLocales.map((language, idx) => {
                                return (
                                    <li
                                        key={idx}
                                        className={classnames({
                                            active: language.locale === locale,
                                        })}
                                    >
                                        <Link to={`/${language.locale}/${uri}`}>
                                            {language.display}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                        {!mobile ? (
                            <div className="close" onClick={languageToggle} />
                        ) : null}
                    </div>
                    </div>
                </div>
            </div>
        : ''}
    </>
);

export default LanguageNav;
