import PropTypes from "prop-types"
import React, { Component } from "react"
import { changeLocale, injectIntl } from "gatsby-plugin-intl";
import IntlLink from './link';
import { withPrefix } from "gatsby"
import classNames from "classnames"
import { Location, Match } from "@reach/router"
import LanguageNav from "./languageNav"
import Overlay from "./overlay"
import { throttle } from "lodash"
import { Helmet } from "react-helmet"
import { getWindow } from 'ssr-window';
import isIE from '../util/isIE';
import { LOCALES, DISABLED_LANGUAGES } from "../common/constants"

const window = getWindow();

const NotDownloadOrThanksPage = ({children}) => (
    <Match path='/:locale/download'>
        {({match}) => (!match ?
            <Match path='/:locale/thanks'>
                {({match}) => (!match ? children : null)}
            </Match>
        : null)}
    </Match>
);

export const NotPaidMeida = ({ children }) => (
    <Match path='/:locale/report-request'>
        {({ match }) => (!match ?
            <Match path='/:locale/report-request-thanks'>
                {({ match }) => (!match ? children : null)}
            </Match>
            : null)}
    </Match>
);

class Header extends Component {
    constructor() {
        super()

        const disabledLanguages = DISABLED_LANGUAGES ? DISABLED_LANGUAGES : [];
        const enabledLocales = LOCALES.filter(x => !disabledLanguages.includes(x.locale));

        this.state = {
            languageNav: false,
            isMobile: window.innerWidth < 1200,
            overlayActive: false,
            enabledLocales,
            disabledLanguages,
            scrolled: false,
        }
    }

    /* Language wrap - Notes
    - when user clicks the .language-select in the masthead, both .masthead and .language-wrap get the class .active
    - when user clicks .close or selects a language in .language-wrap, .active class is removed from both .masthead and .language-wrap
    */
    languageToggle = () => {
        const { languageNav } = this.state
        this.setState({ languageNav: !languageNav })
    }

    overlayToggle = () => {
        const { overlayActive } = this.state
        this.setState({ overlayActive: !overlayActive })
    }

    mastheadToggle = (e) => {
        const {target} = e;
        if(target) {
            const { classList } = target;

            if(classList.contains('language-select')) {
                this.languageToggle();
            } else if(classList.contains('menu-trigger')) {
                this.overlayToggle();
            }
        }
    }

    componentDidMount() {
        window.addEventListener(
            "resize",
            throttle(() => {
                window.innerWidth < 1200
                    ? this.setState({ isMobile: true })
                    : this.setState({ isMobile: false })
            }, 500)
        )
        window.addEventListener("scroll", throttle(() => {
            window.scrollY > 50 ? this.setState({ scrolled: true }) : this.setState({ scrolled: false });
        }), 200);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {});
        window.removeEventListener("scroll", () => {});
    }

    render() {
        const { intl } = this.props
        const { languageNav, isMobile, overlayActive, enabledLocales, disabledLanguages, scrolled } = this.state;
        const { locale } = intl;
        const translated = LOCALES.find(lang => lang.locale === locale);

        if (disabledLanguages.length) {
            disabledLanguages.forEach(element => {
                if (intl.locale == element) {
                    changeLocale("en")
                }
            })
        }

        return (
            <>
            <Helmet>
                <script src={withPrefix("cookie.js")} type="text/javascript" />
            </Helmet>

            <Location>
                {({location}) => {
                    const uri = location.pathname.replace(/^\/+|\/+$/g, "").split("/")

                    return (
                        <>
                            <Overlay
                                uri={uri}
                                active={overlayActive}
                                overlayToggle={this.overlayToggle}
                                enabledLocales={enabledLocales}
                            />


                        <div>
                        </div>
                        <LanguageNav
                            active={languageNav}
                            locale={locale}
                            uri={uri.slice(1).join('/')}
                            languageToggle={this.languageToggle}
                            enabledLocales={this.state.enabledLocales}
                        />

                        <div onClick={this.mastheadToggle}>
                            <div className={classNames('masthead-wrapper', { scrolled })}>
                                <div className="masthead">

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">


                                                {/* LOGO */}
                                                {isIE() ? (
                                                    <IntlLink to="/not-supported">
                                                        <div className="logo" />
                                                    </IntlLink>
                                                ) : (
                                                    <IntlLink to={`/`}>
                                                        <div className="logo" />
                                                    </IntlLink>
                                                )}


                                                {/* Utilities */}
                                                <ul className="utilities">
                                                    {enabledLocales.length > 1 ?
                                                        <li>
                                                            <a
                                                                className="language-select"
                                                                title=""
                                                            >
                                                                {translated ? translated.display : locale}
                                                            </a>
                                                        </li>
                                                    : ''}
                                                    <li className="contact">
                                                        <a
                                                            className="button secondary"
                                                            href="mailto:globalpaymentsreport@fisglobal.com"
                                                            title={intl.formatMessage({
                                                                id: "cta_contact_us",
                                                            })}
                                                            target="_blank"
                                                        >
                                                            {intl.formatMessage({
                                                                id: "cta_contact_us",
                                                            })}
                                                        </a>
                                                    </li>
                                                    <NotPaidMeida>
                                                        <NotDownloadOrThanksPage>
                                                            <li>
                                                                <IntlLink
                                                                    className="button primary"
                                                                    to="/download"
                                                                    title={intl.formatMessage({
                                                                    id: "cta_download",
                                                                    })}
                                                                >
                                                                {intl.formatMessage({
                                                                    id: "cta_download",
                                                                })}
                                                                </IntlLink>
                                                            </li>
                                                        </NotDownloadOrThanksPage>
                                                    </NotPaidMeida>
                                                    <li className="menu">
                                                        <span className="menu-trigger" />
                                                    </li>
                                                </ul>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </>
                )
                }}
            </Location>
            </>
        )
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default injectIntl(Header)
