import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Link from './link';
import classNames from "classnames"
import LanguageNav from "./languageNav"
import { NotPaidMeida } from "./header";
import { MARKETS } from '../common/constants';
import { paramCase } from 'change-case';

const Overlay = ({ intl, uri, active, overlayToggle, enabledLocales }) => (
  <div className={classNames("overlay main-navigation", { active })}>
    <div className="overlay-inner">
      <div className="container-fluid">
        <div className="row overlay-masthead">
          <div className="col-12">
            <div className="logo" />
            <div className="close" onClick={overlayToggle} />
          </div>
        </div>

        <NotPaidMeida>
        <div className="row market-wrap">
          <div className="col-xl-8">
            <h2>{intl.formatMessage({ id: 'footer_heading' })}</h2>
          </div>
        </div>
        <div className="row">
          {MARKETS.map(market => (
              <div className="col-xl-3" key={market}>
                <div className={`market-cta ${paramCase(market)}`}>
                  <Link to={`/market-guide/${paramCase(market)}`}>
                    <div className="content">
                      <span className="market-title">{intl.formatMessage({ id: `${market}_nav_1` })}</span>
                      <span className="button secondary">
                          {intl.formatMessage({ id: 'cta_preview' })}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
          ))}
        </div>
        </NotPaidMeida>

        <div className="row mobile-only">
          <div className="col-12">
            <ul>
              <NotPaidMeida>
              <li><Link className="button primary" to="/download">
                  {intl.formatMessage({ id: 'cta_download' })}
              </Link></li>
              </NotPaidMeida>
              <li>
                <a
                    href="mailto:globalpaymentsreport@fisglobal.com"
                    title={intl.formatMessage({ id: "cta_contact_us" })}
                    target="_blank"
                    className="button secondary"
                >
                  {intl.formatMessage({ id: "cta_contact_us" })}
                </a>
              </li>
            </ul>

          </div>
        </div>

        <LanguageNav
          uri={uri.slice(1).join('/')}
          mobile
          active={true}
          enabledLocales={enabledLocales}
        />
      </div>
    </div>
  </div>
);


export default injectIntl(Overlay);
