import React from "react"
import { Link as GatsbyLink } from "gatsby-plugin-intl"
import { Location } from '@reach/router'

const Link = props => {
    const { children, to } = props;

    return (
        <Location>
            {({location: { search }}) => (
                <GatsbyLink {...props} to={`${to}${search}`} >
                    {children}
                </GatsbyLink>
            )}
        </Location>
    )
}

export default Link;
