export const LOCALES = [
    {
        locale: 'en',
        display: 'EN',
    },
    {
        locale: 'zh',
        display: '中文',
    },
    {
        locale: 'ja',
        display: '日本語',
    },
    {
        locale: 'es',
        display: 'ES',
    },
    {
        locale: 'pt',
        display: 'PT-BR',
    },
    {
        locale: 'fr',
        display: 'FR',
    },
    {
        locale: 'de',
        display: 'DE',
    },
];

export const CORE_FORMS = {
    en: {
        id: 1273,
        subscriber: '092-EMI-875',
        domain: 'empower2.fisglobal.com'
    },
}

export const MARKETS = [
    'argentina',
    'malaysia',
    'nigeria',
    'south_africa',
    'uae',
    'thailand',
    'south_korea',
]

export const ECOMM_FORMS = {
    en: {
        id: 5988,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    zh: {
        id: 5983,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    es: {
        id: 5985,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    fr: {
        id: 5981,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    de: {
        id: 5982,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    ja: {
        id: 5986,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    pt: {
        id: 5987,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
}

export const PAID_FORMS = {
    en: {
        id: 6218,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    zh: {
        id: 6212,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    es: {
        id: 6216,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    fr: {
        id: 6213,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    de: {
        id: 6214,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    ja: {
        id: 6215,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
    pt: {
        id: 6217,
        subscriber: '850-JOA-856',
        domain: 'offers.worldpayglobal.com',
    },
};

// "pt","es","de"
export const DISABLED_LANGUAGES = [];

export const FIELD_MAP = [
    {
        core: "FirstName",
        ecomm: "FirstName",
    },
    {
        core: "LastName",
        ecomm: "LastName",
    },
    {
        core: false,
        ecomm: "Title",
    },
    {
        core: "Email",
        ecomm: "Email",
    },
    {
        core: "Company",
        ecomm: "Company",
    },
    {
        core: "Phone",
        ecomm: "Phone",
    },
    {
        core: "Region__c",
        ecomm: "region",
    },
    {
        core: false,
        ecomm: "AnnualRevenue",
    },
    {
        core: false,
        ecomm: "LeadSource",
    },
    {
        core: "Industry",
        ecomm: "Core_Vertical__c",
    },
    {
        core: "option3",
        ecomm: "howwouldyouliketoacceptpayments"
    }
]

export const REPORT_LINKS = {
    en: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1297411 GPR DIGITAL ENGLISH SINGLES RGB FNL11.pdf',
    zh: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1307921 GPR DIGITAL ALL PAGES SINGLES RGB FNL_CH_5.4.21.pdf',
    es: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1297411 GPR DIGITAL ALL PAGES SINGLES RGB FNL%28ESP%29 D2.pdf ',
    fr: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1297411 GPR DIGITAL ALL PAGES SINGLES RGB FNL7%28FR%29 D3.pdf',
    de: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1297411 GPR DIGITAL ALL PAGES SINGLES RGB FNL7%28GER%29 D2.pdf',
    ja: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/050621%20GPR%20DIGITAL%20ALL%20PAGES%20SINGLES%20RGB%20FNL3_JP_web_72ppi.pdf',
    pt: 'https://offers.worldpayglobal.com/rs/850-JOA-856/images/1297411 GPR DIGITAL ALL PAGES SINGLES RGB FNL%28POR%29 D1.pdf',
}

export const EMAIL_BLACKLIST = [
    "gmail.",
    "yahoo.",
    "hotmail.",
    "live.",
    "aol.",
    "outlook.",
    "163.com.",
    "qq.com.",
    "sina.com.",
    "126.com.",
    "yeah.net.",
    "adyen.",
    "stripe.",
    "ingenico.",
    "squareup.",
    "example.com",
    "fiserv.com",
    "tsys.com",
    "paymentech.com",
    "braintreepayments.com",
    "squareup.com",
    "gocardless.com",
    "cardconnect.com",
    "clover.com",
    "safecharge.com",
    "oracle.com",
    "currencycloud.com",
    '.edu',
    '.ac.uk',
];
